import { contactLaunchScreenImage } from "/src/bundled/index";
import { AsyncImage, ResizableImage } from "/src/graphics/index";

import { Rect } from "cacao/graphics";

class ContactPresentationSnapshotter {
  
  static get contact(){
    return new this({ backgroundImageSrc: contactLaunchScreenImage });
  }
  
  static get sent(){
    return new this();
  }
  
  constructor(options){
    const { backgroundImageSrc } = options ?? {};
    this.backgroundImageSrc = backgroundImageSrc;
  }
  
  async prepare(){
    if (this.backgroundImage) {
      return;
    }
    
    const { backgroundImageSrc } = this;
    if (backgroundImageSrc) {
      this.backgroundImage = await AsyncImage(backgroundImageSrc);
    }
  }
  
  drawSnapshotInContext(ctx, node, nodeRect){
    const { draw, backgroundImage } = this;
    
    if (backgroundImage) {
      const resizable = new ResizableImage(backgroundImage, {
        scale: 2,
        insets: { top: 270/2, left: 128/2, bottom: 88/2, right: 64/2 }
      });
      
      const resized = resizable.resizedTo({ width: nodeRect.width, height: nodeRect.height }, window.devicePixelRatio);
      
      ctx.drawImage(resized, 0, 0, nodeRect.width, nodeRect.height);
    }
    
    // Draw backdrop:
    const backdrop = node.querySelector(".backdrop");
    if (backdrop) {
      const backdropStyle = window.getComputedStyle(backdrop);
      
      ctx.fillStyle = backdropStyle.backgroundColor;
      ctx.fillRect(0, 0, nodeRect.width, nodeRect.height);
    }
    
    // Draw elements:
    const matches = node.querySelectorAll("label, input, button, h1, h2");
    matches.forEach((element) => {
      ctx.save();
      
      const frame = element.getBoundingClientRect();
      const r = Rect.make(frame.x, frame.y, frame.width, frame.height);
      r.origin.x -= nodeRect.x;
      r.origin.y -= nodeRect.y;
      
      const style = window.getComputedStyle(element);
      ctx.font = style.font;
      
      const paddingTop = parseInt(style.paddingTop, 10);
      const paddingLeft = parseInt(style.paddingLeft, 10);
      const paddingBottom = parseInt(style.paddingBottom, 10);
      
      let text, textColor;
      
      if (element.tagName.toLowerCase() == "button") {
        
        ctx.fillStyle = style.backgroundColor;
        
        ctx.beginPath();
        ctx.roundRect(r.x, r.y, r.width, r.height, r.height * 0.5);
        ctx.fill();
      }
      
      r.origin.y += paddingTop;
      r.origin.x += paddingLeft;
      r.size.height -= paddingBottom;
      
      if (element.tagName.toLowerCase() == "input") {
        text = element.placeholder;
        textColor = "darkgray";
        
        // This needs to be forced on Firefox.
        ctx.letterSpacing = "-1px";
      } else {
        text = element.textContent;
        textColor = style.color;
      }
      
      if (text) {
        ctx.textBaseline = "middle";
        ctx.fillStyle = textColor;
        ctx.fillText(text, Math.floor(r.x), r.midY, r.width);
      }
     
      ctx.restore();
    });
  }
  
  
}

export default ContactPresentationSnapshotter;
