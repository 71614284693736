import { View } from "cacao/ui";
import { localized } from "/src/locale/index";
import Button from "./Button";

class DeleteConfirmationOverlay extends View {
  
  constructor(){
    super();
    
    this.node.className = "delete-confirmation-overlay";
    this.node.innerHTML = `
      <div class="backdrop"></div>
      <div class="delete-button-wrapper"></div>
    `;
    
    const deleteButton = new Button({ type: Button.Type.rounded, role: Button.Role.destructive });
    deleteButton.text = localized("contact.delete-button");
    deleteButton.primaryAction = (event) => {
      event.stopPropagation();
      
      this.actions?.didDelete();
    };
    
    const deleteContainerNode = this.node.querySelector(".delete-button-wrapper");
    this.deleteContainerNode = deleteContainerNode;
    
    deleteContainerNode.appendChild(deleteButton.node);
  }
  
  show(options){
    const { containerNode, sourceNode } = options;
    
    if (this.presentation) {
      return;
    }
    
    const containerNodeRect = containerNode.getBoundingClientRect();
    const sourceNodeRect = sourceNode.getBoundingClientRect();
    const { deleteContainerNode } = this;
    
    deleteContainerNode.style.top = `${sourceNodeRect.y - containerNodeRect.y}px`;
    deleteContainerNode.style.left = `${sourceNodeRect.x - containerNodeRect.x}px`;
    deleteContainerNode.style.maxWidth = `${containerNodeRect.width}px`;
    
    containerNode.appendChild(this.node);
    
    this.presentation = { ...options };
    
    const present = () => {
      this.node.classList.add("presented");
      
      const animation = deleteContainerNode.animate([
        { maxWidth: `${sourceNodeRect.width}px`, opacity: 0 },
        { maxWidth: `${sourceNodeRect.width}px`, opacity: 1, offset: 0.05 },
        { maxWidth: `${containerNodeRect.width}px`, opacity: 1 },
      ], {
        duration: 800,
        easing: "ease-in"
      });
      
      animation.finished.then(() => {
        this.addSelectOutsideHandler();
      });
    };
    
    window.requestAnimationFrame(present);
  }
  
  dismiss(){
    const { sourceNode } = this.presentation;
    const { deleteContainerNode } = this;
    
    const sourceNodeRect = sourceNode.getBoundingClientRect();
    
    const dismiss = () => {
      this.node.classList.remove("presented");
      
      const animation = deleteContainerNode.animate([
        { maxWidth: sourceNode.style.maxWidth, opacity: 1 },
        { maxWidth: `${sourceNodeRect.width}px`, opacity: 1, offset: 0.95 },
        { maxWidth: `${sourceNodeRect.width}px`, opacity: 0 }
      ], {
        duration: 800,
        easing: "ease-out",
        fill: "forwards"
      });
      
      animation.finished.then(() => {
        this.node.remove();
        this.presentation = undefined;
        
        if (this.didDismiss){
          this.didDismiss();
        }
      });
    };
    
    window.requestAnimationFrame(dismiss);
    
    if (this.removeSelectOusideHandler){
      this.removeSelectOusideHandler();
      this.removeSelectOusideHandler = undefined;
    }
  }
  
  didSelectOutside(event){
    if (event.target == this.deleteContainerNode.querySelector("button")) {
      return;
    }
    
    this.actions?.didCancel();
  }
  
  addSelectOutsideHandler(){
    const event = "click";
    const handler = (event) => {
      this.didSelectOutside(event);
    };
    
    document.addEventListener(event, handler);
    
    this.removeSelectOusideHandler = () => {
      document.removeEventListener(event, handler);
    }
  }
  
}

export default DeleteConfirmationOverlay;
