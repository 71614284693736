import { View } from "cacao/ui";
import SheetTransitionCoordinator from "./SheetTransitionCoordinator";
import ContactPresentationSnapshotter from "./ContactPresentationSnapshotter";
import NavigationHeader from "./NavigationHeader";
import ContactFormView from "./ContactFormView";
import Button from "./Button";
import DeleteConfirmationOverlay from "./DeleteConfirmationOverlay";
import SentConfirmationOverlay from "./SentConfirmationOverlay";
import { localized } from "/src/locale/index";
import { ShakeAnimator } from "/src/shake/index";
import { APIClient } from "/src/api/index";

class EditingState {
  static editable = 0;
  static intermediate = 1;
  static sent = 2;
}

class ContactView extends View {
  
  makeNode(document){
    return document.createElement("dialog");
  }
  
  constructor(){
    super();
    
    this.node.classList = "sheet-dialog";
    this.node.innerHTML = `
    <div class="sheet-backdrop"></div>
    <div class="sheet-transitioning-container"></div>
    
    <div class="sheet">
      <div class="sheet-container">
        <div class="sheet-content"></div>
      </div>
    </div>
    
    <div class="sheet-hidden">
      <span id="label">Contacto</span>
    </div>
    `;
    
    this.editingState = EditingState.editable;
    
    // Accessibility labels:
    this.node.setAttribute("role", "dialog");
    this.node.setAttribute("aria-labelledby", "label");
    this.node.setAttribute("aria-modal", true);
    
    // Actions:
    const dismissButton = new Button({ type: Button.Type.plain });
    dismissButton.node.ariaLabel = localized("contact.close-button");
    dismissButton.node.innerHTML = `
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
      <use href="#x-close" />
    </svg>
    `;
    dismissButton.primaryAction = (event) => {
      // Cancel if not editing:
      if (this.editingState != EditingState.editable) {
        return;
      }
      
      // Cancel a pending confirmation if needed:
      if (this.pendingConfirmation) {
        this.pendingConfirmation.dismiss();
        return;
      }
      
      this.formView.endEditing();
      
      // Dismiss right away if has no edits:
      if (!this.formView.hasChanges) {
        this.dismiss();
        return;
      }
      
      const confirmation = new DeleteConfirmationOverlay();
      confirmation.didDismiss = () => {
        if (confirmation == this.pendingConfirmation) {
          this.pendingConfirmation = undefined;
        }
      };
      confirmation.actions = {
        didDelete: () => {
          this.dismiss(SheetTransitionCoordinator.TransitionStyle.destroy);
        },
        
        didCancel: () => {
          confirmation.dismiss();
        }
      };
      
      confirmation.show({
        containerNode: this.node.querySelector(".sheet-container"),
        sourceNode: dismissButton.node
      });
      
      this.pendingConfirmation = confirmation;
    };
    
    const submitButton = new Button({ type: Button.Type.rounded });
    submitButton.text = localized("contact.send-button");
    submitButton.node.ariaLabel = localized("contact.send-button");
    submitButton.primaryAction = (event) => {
      this.submit();
    };
    
    // Configure header:
    const sheetContent = this.node.querySelector(".sheet-content");
    
    const headerView = new NavigationHeader();
    headerView.configure({
      leading: [ dismissButton ],
      trailing: [ submitButton ]
    });
    
    sheetContent.appendChild(headerView.node);
    
    this.headerView = headerView;
    
    // Configure form:
    const formView = new ContactFormView();
    
    // Scroll to bottom if caret at end because of some WebKit bug that doesn't take into account the element's text area bottom padding:
    formView.textInputDidChange = (sender, context) => {
      if (context.isAtEnd) {
        sheetContent.scrollTop = sheetContent.scrollHeight;
      }
    };
    
    sheetContent.appendChild(formView.node);
    
    this.formView = formView;
    
    // Customize the `cancel` dialog behavior so we call the appropriate custom dismiss mechanism.
    const dialog = this.node;
    dialog.addEventListener("cancel", (event) => {
      event.preventDefault();
      
      dismissButton.triggerPrimaryAction();
    }, true);
    
    // Handle `touchmove`:
    // This is a nightmare, MobileSafari does way too much magic stuff to avoid `fixed` elements hiding under the keyboard. To make matters worse, `overscroll-behavior` isn't reliable on MobileSafari and thus the whole page will be scrolled under the sheet.
    // We'll try to hide the keyboard and prevent scrolling whatever possible.
    
    const canScroll = element => element.scrollHeight > element.clientHeight;
    
    this.node.addEventListener("touchmove", (event) => {
      const { activeElement } = document;
      const hasFocusedElement = (activeElement && sheetContent.contains(activeElement));
      
      let triggerEventPreventDefault = false;
      
      // If there's a focused element and the user is editing, we don't want them scrolling:
      if (hasFocusedElement) {
        triggerEventPreventDefault = true;
      } else {
        // If not editing and scrolling is possible, prevent only if touching inside the content:
        if (canScroll(sheetContent)) {
          const isInside = sheetContent.contains(event.target);
          if (!isInside) {
            triggerEventPreventDefault = true;
          }
        }
        // If can't scroll, always prevent or else MobileSafari will end up scrolling the whole body:
        else {
          triggerEventPreventDefault = true;
        }
      }
      
      // Prevent if needed:
      if (triggerEventPreventDefault) {
        event.preventDefault();
      }
      
      formView.endEditing();
    });
  }
  
  submit(){
    const form = this.formView.node;
    const didSatisfy = form.checkValidity();
    
    this.formView.endEditing();
    
    if (!didSatisfy) {
      const animator = new ShakeAnimator();
      animator.animate(this.node.querySelector(".sheet"), () => {
        form.reportValidity();
      });
      return;
    }
    
    this.node.querySelector(".sheet").classList.add("intermediate-progress");
    this.editingState = EditingState.intermediate;
    
    (async () => {
      const parameters = {};
      const data = new FormData(form);
      data.forEach(function(value, key){
        parameters[key] = value;
      });
      
      const response = await APIClient.sharedClient.submitContact(parameters);
      
      if (!response) {
        this.editingState = EditingState.editable;
        this.node.querySelector(".sheet").classList.remove("intermediate-progress");
        
        alert(localized("contact.cannot-send-message-alert"));
        return;
      }
      
      // Present confirmation:
      const confirmation = new SentConfirmationOverlay();
      confirmation.primaryAction = (event) => {
        this.dismiss();
      };
      
      confirmation.show({
        containerNode: this.node.querySelector(".sheet-container")
      }, () => {
        this.formView.removeFromSuperview();
        this.headerView.removeFromSuperview();
        
        this.node.querySelector(".sheet").classList.remove("intermediate-progress");
        
        this.transitionCoordinator.snapshotter = ContactPresentationSnapshotter.sent;
      });
      
    })();
  }
  
  // - Presentation
  
  show(options){
    if (this.transitionCoordinator) {
      throw new Error("State inconsistency error, view already shown.");
      return;
    }
    
    const { containerElement, sourceElement } = options;
    
    // TODO: `sourceElement` is optional, do a simple transition if that's the case
    if (!sourceElement) {
      throw new Error("Not providing a `sourceElement` isn't supported.");
      return;
    }
    
    this.node.querySelector(".sheet-container").style.visibility = "hidden";
    
    containerElement.appendChild(this.node);
    
    const dialog = this.node;
    dialog.showModal();
    
    const coordinatorOptions = {
      containerNode: this.node.querySelector(".sheet-transitioning-container"),
      sheetNode: this.node.querySelector(".sheet-container"),
      sourceNode: sourceElement
    };
    
    const coordinator = new SheetTransitionCoordinator(coordinatorOptions);
    coordinator.snapshotter = ContactPresentationSnapshotter.contact;
    coordinator.delegate = this;
    coordinator.show();
    
    this.transitionCoordinator = coordinator;
  }
  
  dismiss(transitionStyle){
    if (this.transitionCoordinator.isTransitioning) {
      return;
    }
    
    this.editingState = EditingState.intermediate;
    this.transitionCoordinator.dismiss(transitionStyle);
  }
  
  // - SheetTransitionCoordinator
  
  sheetTransitionCoordinatorWillBegin(coordinator, direction){
    const { node } = this;
    if (direction) {
      node.querySelector(".sheet-backdrop").classList.add("appear");
    } else {
      node.querySelector(".sheet-backdrop").classList.remove("appear");
    }
    node.querySelector(".sheet-transitioning-container").style.display = "";
  }
  
  sheetTransitionCoordinatorDidComplete(coordinator, direction){
    const { node } = this;
    if (direction) {
      node.querySelector(".sheet-container").style.visibility = "";
      node.querySelector(".sheet-transitioning-container").style.display = "none";
      
      // Focus first button for screen readers:
      node.querySelector("button")?.focus();
    } else {
      const dialog = this.node;
      dialog.close();
      
      this.removeFromSuperview();
    }
    
  }
  
}

export { ContactView };
