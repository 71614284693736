import { View } from "cacao/ui";

class ButtonType {
  
  static get plain() {
    return "plain-button";
  };
  
  static get rounded() {
    return "rounded-button";
  };
  
}

class ButtonRole {
  
  static get destructive(){
    return "destructive";
  }
  
}

class Button extends View {
  
  static get Type(){ return ButtonType };
  static get Role(){ return ButtonRole };
  
  constructor(configuration){
    super();
    
    const { type, role } = configuration;
    
    if (type){
      this.node.className = type;
    }
    
    if (role){
      this.node.classList.add(role);
    }
    
    this.node.addEventListener("click", this.triggerPrimaryAction.bind(this));
  }
  
  makeNode(document){
    return document.createElement("button");
  }
  
  triggerPrimaryAction(event){
    const { primaryAction } = this;
    if (primaryAction) {
      primaryAction(event);
    }
  }
  
  set text(text){
    this.node.textContent = text;
  }
  
  get text(){
    return this.node.textContent;
  }
  
}

export default Button;
