
class ResizableImageMode {
  static get stretch(){
    return "stretch";
  }
  
  static get tile(){
    return "tile";
  }
}

class ResizableImage {
  
  static get mode(){
    return ResizableImageMode;
  }
  
  constructor(sourceImage, { scale = 1, insets, mode = ResizableImageMode.stretch} = options){
    this.sourceImage = sourceImage; // Image
    this.sourceImageScale = scale; // Number
    this.insets = insets; // { top, left, bottom, right }
    this.mode = mode; // "stretch" | "tile"
  }
  
  resizedTo({ width, height } = size, scale = 1){
    let { sourceImage, sourceImageScale, insets, mode } = this;
    
    // Prepare destination:
    const scaledWidth = width * scale;
    const scaledHeight = height * scale;
    
    const img = document.createElement("canvas");
    img.width = scaledWidth;
    img.height = scaledHeight;
    
    const ctx = img.getContext("2d");
    ctx.scale(scale, scale);
    
    // Pre-draw source image:
    const sourceImageNaturalSize = { width: sourceImage.width / sourceImageScale, height: sourceImage.height / sourceImageScale };
    const rasterizationScale = window.devicePixelRatio; // TODO: Maybe allow this to be customized
    
    // Replace source image:
    sourceImage = offscreenRasterizedImage(sourceImage, sourceImageNaturalSize.width, sourceImageNaturalSize.height, rasterizationScale);
    sourceImageScale = rasterizationScale;
    
    // Source/destination scaled dimensions:
    const sSize = { width: sourceImage.width, height: sourceImage.height};
    const sInsets = {
      top: insets.top * sourceImageScale,
      left: insets.left * sourceImageScale,
      bottom: insets.bottom * sourceImageScale,
      right: insets.right * sourceImageScale 
    };
    
    const sTopRightX = sSize.width - sInsets.right;
    const sBottomY = sSize.height - sInsets.bottom;
    
    const topRightX = width - insets.right;
    const bottomY = height - insets.bottom;
    
    const sEdgeWidth = sSize.width - sInsets.left - sInsets.right;
    const sEdgeHeight = sSize.height - sInsets.top - sInsets.bottom;
    
    const edgeWidth = width - insets.right - insets.left;
    const edgeHeight = height - insets.top - insets.bottom;
    
    // Edges:
    
    // Top-left corner
    ctx.drawImage(sourceImage, 0, 0, sInsets.left, sInsets.top, 0, 0, insets.left, insets.top);
    
    // Top-right corner
    ctx.drawImage(sourceImage, sTopRightX, 0, sInsets.right, sInsets.top, topRightX, 0, insets.right, insets.top);
    
    // Bottom-left corner
    ctx.drawImage(sourceImage, 0, sBottomY, sInsets.left, sInsets.bottom, 0, bottomY, insets.left, insets.bottom);
    
    // Bottom-right corner
    ctx.drawImage(sourceImage, sTopRightX, sBottomY, sInsets.right, sInsets.bottom, topRightX, bottomY, insets.right, insets.bottom);
    
    // Edges:
    const fill = (sx, sy, sw, sh, dx, dy, dw, dh) => {
      if (mode === ResizableImageMode.stretch) {
        ctx.drawImage(sourceImage, sx, sy, sw, sh, dx, dy, dw, dh);
      } else {
        // TODO: fill in by tiling
      }
    };
    
    
    // Top:
    fill(
      sInsets.left, 0, sEdgeWidth, sInsets.top,
      insets.left, 0, edgeWidth, insets.top
    );
    
    // Bottom:
    fill(
      sInsets.left, sSize.height - sInsets.bottom, sEdgeWidth, sInsets.bottom,
      insets.left, height - insets.bottom, edgeWidth, insets.bottom,
    );
    
    // Left:
    fill(
      0, sInsets.top, sInsets.left, sEdgeHeight,
      0, insets.top, insets.left, edgeHeight
    );
    
    // Right:
    fill(
      sSize.width - sInsets.right, sInsets.top, sInsets.right, sEdgeHeight,
      width - insets.right, insets.top, insets.right, edgeHeight
    );
    
    // Center:
    fill(
      sInsets.left, sInsets.top, sEdgeWidth, sEdgeHeight,
      insets.left, insets.top, edgeWidth, edgeHeight
    );
    
    return img;
  }
  
}

// This pre-draw step is required for SVGs to be rendered correctly on Firefox.
// I also think it's faster to render each patch portion canvas-to-canvas.
function offscreenRasterizedImage(image, width, height, scale = 1) {
  const scaledWidth = width * scale;
  const scaledHeight = height * scale;
  
  let canvas;
  if (window.OffscreenCanvas) {
    canvas = new OffscreenCanvas(scaledWidth, scaledHeight);
  } else {
    canvas = document.createElement("canvas");
    canvas.width = scaledWidth;
    canvas.height = scaledHeight;
  }
  
  const ctx = canvas.getContext("2d");
  ctx.scale(scale, scale);
  ctx.drawImage(image, 0, 0, width, height);
  
  return canvas;
}

export default ResizableImage;
