import { PropertyAnimator, TimingCurve } from "cacao/ui";
import { assert } from "cacao/core";

// If not falls back on `PropertyAnimator`
const supportsBrowserAnimations = (!!Element.prototype.animate);

// For some reason, syncing up animations on WebKit looks janky.
const setStartTimeRequired = navigator.userAgent.includes("Firefox");

class GenieTransitionAnimator {
  didComplete;
  supportsBrowserAnimations;
  
  constructor(animation, image, scale){
    assert(animation, "An animation is required.");
    assert(image, "A source image is required.");
    
    this.genieAnimation = animation;
    this.image = image;
    this.scale = scale;
    this.data = animation.calculate();
    
    this.frame = 0;
    this._createElement();
    this._applyFrame();
  }
  
  animate(){
    if (!this.data){
      // TODO: Animation is not possible.
      
      return;
    }
    
    const { genieAnimation } = this;
    const { duration } = genieAnimation.options;
    
    const completion = () => {
      const { didComplete } = this;
      if (didComplete){
        didComplete();
      }
    };
    
    if (supportsBrowserAnimations) {
      const options = {
        easing: "linear",
        duration: duration,
        fill: "forwards"
      };
      
      const { frame, data, sliceElements } = this;
      const { slices, transforms } = data;
      
      const startTime = (setStartTimeRequired) ? document.timeline.currentTime : undefined;
      
      const animations = slices.map((slice, idx) => {
        const transformsForSlice = transforms[idx];
        const element = sliceElements[idx];
        
        const animation = element.animate({ transform: transformsForSlice }, options);
        
        if (setStartTimeRequired) {
          animation.startTime = startTime;
        }
        
        return animation;
      });
      
      animations[0].finished.then(completion);
      
    } else {
      const options = { timingCurve: TimingCurve.linear };
      const animator = new PropertyAnimator(options);
      animator.didAnimate = () => {
        this._applyFrame();
      };
      
      animator.animate(this, { frame: genieAnimation.frameCount }, duration, completion);
    }
  }
  
  _createElement(){
    const { image, scale, data } = this;
    const { slices } = data;
    
    const fragment = new DocumentFragment();
    const overdrawHeight = 1;
    
    const sliceElements = [];
    const containerSize = { width: 0.0, height: 0.0 };
    
    slices.forEach((slice, idx) => {
      const { frame } = slice;
      const overdraw = (idx == 0) ? 0 : overdrawHeight;
      
      const element = document.createElement("canvas");
      element.width = frame.width * scale;
      element.height = (frame.height + overdraw * 2) * scale;
      element.style.width = `${frame.width}px`;
      element.style.height = `${frame.height + overdraw * 2}px`;
      element.style.position = "absolute";
      element.style.left = "0px";
      element.style.top = `-${overdraw}px`;
      element.style.imageRendering = "pixelated";
      element.style.zIndex = slices.length - idx;
      element.style.transformOrigin = "0px 0px";
      element.style.willAnimate = "transform";
      
      const ctx = element.getContext("2d");
      ctx.scale(scale, scale);
      ctx.drawImage(image, frame.x * scale, (frame.y - overdraw) * scale, frame.width * scale, (frame.height + overdraw * 2) * scale, 0, 0, frame.width, frame.height + overdraw * 2);
      
      fragment.appendChild(element);
      
      sliceElements.push(element);
      
      containerSize.width = frame.width;
      containerSize.height += frame.height;
    });
    
    const container = document.createElement("div");
    container.className = "genie-transition-animator-container";
    container.style.position = "absolute";
    container.style.width = `${containerSize.width}px`;
    container.style.height = `${containerSize.height}px`;
    container.style.transform = "translate3d(0, 0, 0)";
    
    container.appendChild(fragment);
    
    this._element = container;
    
    this.sliceElements = sliceElements;
  }
  
  _applyFrame(){
    const { frame, data, sliceElements } = this;
    const { slices, transforms } = data;
    
    const currentFrame = Math.floor(frame);
    
    slices.forEach((slice, idx) => {
      const transformsForSlice = transforms[idx];
      const element = sliceElements[idx];
      
      element.style.transform = transformsForSlice[currentFrame];
    });
    
  }
  
  get element(){
    return this._element;
  }
  
}

export default GenieTransitionAnimator;
