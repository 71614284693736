import { View } from "cacao/ui"; 

class NavigationHeader extends View {
  
  constructor(){
    super();
    
    this.node.innerHTML = `
      <div class="leading-items"></div>
      <div class="trailing-items"></div>
      
      <span class="background">
        ${ `<span class="layer"></span>`.repeat(7) }
      </span>
    `;
  }
  
  makeNode(document){
    return document.createElement("header");
  }
  
  configure(configuration){
    const setItemsForContainer = (items, container) => {
      container.innerHTML = "";
      
      for (const view of items) {
        container.appendChild(view.node);
      }
    };
    
    const { leading, trailing } = configuration;
    setItemsForContainer(leading, this.node.querySelector(".leading-items"));
    setItemsForContainer(trailing, this.node.querySelector(".trailing-items"));
  }
  
}

export default NavigationHeader;
