import { Rect, Size, Point } from "cacao/graphics";
import { assert } from "cacao/core";

import RectEdge from "./RectEdge";

const curvesAnimationStart = 0.0;
const curvesAnimationEnd = 0.4;
const slideAnimationStart = 0.3;
const slideAnimationEnd = 1.0;

const kSliceSize = 2.0;
const kFPS = 60.0;
const kRenderMargin = 0.0;

function isEdgeVertical(edge) {
  return edge == RectEdge.top || edge == RectEdge.bottom;
}

function isEdgeNegative(d) {
  return (((d) & 2));
}

function axisForEdge(edge) {
  return isEdgeVertical(edge) ? Axis.y : Axis.x;
}

function perpAxis(axis) {
  return axis == Axis.x ? Axis.y : Axis.x;
}

class Axis {
  static x = "x";
  static y = "y";
}

class Trapezoid {
  
  constructor(a, b, c, d){
    this.a = Point.from(a);
    this.b = Point.from(b);
    this.c = Point.from(c);
    this.d = Point.from(d);
  }
  
  copy(){
    return new Trapezoid(this.a, this.b, this.c, this.d);
  }
  
  static get winding(){
    const { top, left, bottom, right } = RectEdge;
    const a = "a", b = "b", c = "c", d = "d";
    
    const winding = new Array();
    winding[top] = [ a, b, c, d ];
    winding[left] = [ c, a, d, b ];
    winding[bottom] = [ d, c, b, a ];
    winding[right] = [ b, d, a, c ];
    
    return winding;
  }
}

class Segment {
  
  constructor(a, b){
    this.a = Point.from(a);
    this.b = Point.from(b);
  }
  
  static make(a, b){
    return new Segment(a, b);
  }
  
  copy(){
    return new Segment(this.a, this.b);
  }
  
}

class Slice {
  frame;
}

class GenieAnimation {
  
  constructor({ originRect, destinationRect, destinationEdge, reverse, duration }){
    this.frameCount = kFPS * (duration / 1000);
    this.options = {
      originRect: Rect.from(originRect),
      destinationRect: Rect.from(destinationRect),
      destinationEdge,
      reverse,
      duration
    };
  }
  
  slice(bounds, axis){
    const totalSize = axis == Axis.y ? bounds.height : bounds.width;
    const origin = Point.zero;
    origin[axis] = kSliceSize;
    
    const sliceSize = axis == Axis.y ? Size.make(bounds.width, kSliceSize) : Size.make(kSliceSize, bounds.height);
    
    const count = Math.ceil(totalSize / kSliceSize);
    const slices = new Array();
    
    for (let i = 0; i < count; i++) {
      const rect = Rect.make(i * origin.x, i * origin.y, sliceSize.width, sliceSize.height);
      const slice = new Slice();
      slice.frame = rect;
      
      slices.push(slice);
    }
    
    return slices;
  }
  
  calculate(){
    const { options } = this;
    const { duration, originRect, destinationRect, destinationEdge, reverse } = options;
    
    const axis = axisForEdge(destinationEdge);
    const pAxis = perpAxis(axis);
    
    const xInset = axis == Axis.y ? -kRenderMargin : 0.0;
    const yInset = axis == Axis.x ? -kRenderMargin : 0.0;
    
    const marginedDestRect = destinationRect.insetBy(xInset*destinationRect.width/originRect.width, yInset*destinationRect.height/originRect.height);
    const endRectDepth = isEdgeVertical(destinationEdge) ? marginedDestRect.height : marginedDestRect.width;
    
    const aPoints = bezierEndPointsForTransition(destinationEdge, originRect.offsetBy(xInset, yInset));
    const bEndPoints = bezierEndPointsForTransition(destinationEdge, marginedDestRect);
    const bStartPoints = aPoints.copy();
    bStartPoints.a[axis] = bEndPoints.a[axis];
    bStartPoints.b[axis] = bEndPoints.b[axis];
    
    const first = new Segment(aPoints.a, bStartPoints.a);
    const second = new Segment(aPoints.b, bStartPoints.b);
    
    // Slice:
    const slices = this.slice(originRect, axis);
    
    // TODO: get the actual height or width of every slice summed:
    let totalSize = 0.0;
    slices.forEach((slice) => {
      const { frame } = slice;
      totalSize += isEdgeVertical(destinationEdge) ? frame.height : frame.width;
    });
    
    const sign = isEdgeNegative(destinationEdge) ? -1.0 : 1.0;
    
    if (sign * (aPoints.a[axis] - bEndPoints.a[axis]) > 0.0) {
      console.warn(`Genie Effect ERROR: The distance between ${RectEdge.description(destinationEdge)} edge of animated view and ${RectEdge.description(destinationEdge)} edge of ${reverse ? "start" : "destination"} rect is incorrect. Animation will not be performed!`);
      return;
    } else if (sign * (aPoints.a[axis] + sign * totalSize - bEndPoints.a[axis]) > 0.0) {
      console.warn(`Genie Effect Warning: The ${RectEdge.description(destinationEdge)} edge of animated view overlaps ${RectEdge.description((destinationEdge + 2) % 4)} edge of ${reverse ? "start" : "destination"} rect. Glitches may occur.`);
    }
    
    // Prepare storage for transforms:
    const transforms = new Array();
    slices.forEach((slice, index) => {
      transforms.push(new Array());
    });
    
    const totalIter = (duration / 1000) * kFPS;
    const tSignShift = reverse ? -1.0 : 1.0;
    
    for (let i = 0; i < totalIter; i++) {
      const progress = (i) / (totalIter - 1.0);  
      const t = tSignShift * (progress - 0.5) + 0.5;
      
      const curveP = progressOfSegmentWithinTotalProgress(curvesAnimationStart, curvesAnimationEnd, t);
      first.b[pAxis] = easeInOutInterpolate(curveP, bStartPoints.a[pAxis], bEndPoints.a[pAxis]);
      second.b[pAxis] = easeInOutInterpolate(curveP, bStartPoints.b[pAxis], bEndPoints.b[pAxis]);
      
      const slideP = progressOfSegmentWithinTotalProgress(slideAnimationStart, slideAnimationEnd, t);
      
      const trs = this.calculateTransformationsFor(slices, destinationEdge, easeInOutInterpolate(slideP, first.a[axis], first.b[axis]), totalSize, first, second, endRectDepth);
      
      trs.forEach((transform, sliceIndex) => {
        transforms[sliceIndex].push(transform);
      });
      
    }
    
    return { slices, transforms };
  }
  
  calculateTransformationsFor(slices, edge, startPosition, totalSize, firstBezier, secondBezier, finalRectDepth){
    const first = firstBezier, second = secondBezier;
    
    const transformations = new Array();
    const axis = axisForEdge(edge);
    
    const rectPartStart = first.b[axis];
    const sign = isEdgeNegative(edge) ? -1.0 : 1.0;
    
    assert((sign * (startPosition - rectPartStart) <= 0.0), "WTF");
    
    let position = startPosition;
    let trapezoid = new Trapezoid(Point.zero, Point.zero, Point.zero, Point.zero);
    trapezoid[Trapezoid.winding[edge][0]] = bezierAxisIntersection(first, axis, position);
    trapezoid[Trapezoid.winding[edge][1]] = bezierAxisIntersection(second, axis, position);
    
    const enumeration = isEdgeNegative(edge) ? slices.slice().reverse() : slices;
    
    enumeration.forEach((slice, idx) => {
      
      const size = isEdgeVertical(edge) ? slice.frame.height : slice.frame.width;
      const endPosition = position + sign * size; // we're not interested in slices' origins since they will be moved around anyway
      
      const overflow = sign * (endPosition - rectPartStart);
      
      if (overflow <= 0.0) { // slice is still in bezier part
        trapezoid[Trapezoid.winding[edge][2]] = bezierAxisIntersection(first, axis, endPosition);
        trapezoid[Trapezoid.winding[edge][3]] = bezierAxisIntersection(second, axis, endPosition);
      }
      else { // final rect part
        let shrunkSliceDepth = overflow * finalRectDepth / totalSize; // how deep inside final rect "bottom" part of slice is
        
        
        trapezoid[Trapezoid.winding[edge][2]] = first.b.copy();
        trapezoid[Trapezoid.winding[edge][2]][axis] += sign * shrunkSliceDepth;
        
        trapezoid[Trapezoid.winding[edge][3]] = second.b.copy();
        trapezoid[Trapezoid.winding[edge][3]][axis] += sign * shrunkSliceDepth;
      }
      
      const transform = this.transformRectToTrapezoid(slice.frame, trapezoid);
      transformations.push(transform);
      
      trapezoid[Trapezoid.winding[edge][0]] = trapezoid[Trapezoid.winding[edge][2]]; // next one starts where previous one ends
      trapezoid[Trapezoid.winding[edge][1]] = trapezoid[Trapezoid.winding[edge][3]];
      
      position = endPosition;
    });
    
    if (isEdgeNegative(edge)) {
      transformations.reverse();
    }
    
    return transformations;
  }
  
  transformRectToTrapezoid(rect, trapezoid){
    const W = rect.size.width;
    const H = rect.size.height;
    
    const x1a = trapezoid.a.x;
    const y1a = trapezoid.a.y;
    
    const x2a = trapezoid.b.x;
    const y2a = trapezoid.b.y;
    
    const x3a = trapezoid.c.x;
    const y3a = trapezoid.c.y;
    
    const x4a = trapezoid.d.x;
    const y4a = trapezoid.d.y;
    
    const y21 = y2a - y1a,
    y32 = y3a - y2a,
    y43 = y4a - y3a,
    y14 = y1a - y4a,
    y31 = y3a - y1a,
    y42 = y4a - y2a;
    
    const a = -H*(x2a*x3a*y14 + x2a*x4a*y31 - x1a*x4a*y32 + x1a*x3a*y42);
    const b = W*(x2a*x3a*y14 + x3a*x4a*y21 + x1a*x4a*y32 + x1a*x2a*y43);
    const c = - H*W*x1a*(x4a*y32 - x3a*y42 + x2a*y43);
    
    const d = H*(-x4a*y21*y3a + x2a*y1a*y43 - x1a*y2a*y43 - x3a*y1a*y4a + x3a*y2a*y4a);
    const e = W*(x4a*y2a*y31 - x3a*y1a*y42 - x2a*y31*y4a + x1a*y3a*y42);
    const f = -(W*(x4a*(H*y1a*y32) - x3a*(H)*y1a*y42 + H*x2a*y1a*y43));
    
    const g = H*(x3a*y21 - x4a*y21 + (-x1a + x2a)*y43);
    const h = W*(-x2a*y31 + x4a*y31 + (x1a - x3a)*y42);
    let i = H*(W*(-(x3a*y2a) + x4a*y2a + x2a*y3a - x4a*y3a - x2a*y4a + x3a*y4a));
    
    const kEpsilon = 0.0001;
    
    if (Math.abs(i) < kEpsilon) {
      i = kEpsilon * (i > 0 ? 1.0 : -1.0);
    }
    
    const init = [ a/i, d/i, 0, g/i, b/i, e/i, 0, h/i, 0, 0, 1, 0, c/i, f/i, 0, 1.0 ];
    const transform = new DOMMatrix(init);
    
    return transform;
    
  }
  
}

function bezierEndPointsForTransition(edge, endRect){
  switch (edge) {
    case RectEdge.top:
      return Segment.make(Point.make(endRect.minX, endRect.minY), Point.make(endRect.maxX, endRect.minY));
    case RectEdge.bottom:
      return Segment.make(Point.make(endRect.maxX, endRect.maxY), Point.make(endRect.minX, endRect.maxY));
    case RectEdge.right:
      return Segment.make(Point.make(endRect.maxX, endRect.minY), Point.make(endRect.maxX, endRect.maxY));
    case RectEdge.left:
      return Segment.make(Point.make(endRect.minX, endRect.maxY), Point.make(endRect.minX, endRect.minY));
  }
  
  throw new Error("State error");
}

function progressOfSegmentWithinTotalProgress(a, b, t){
  assert(b > a);
  
  return Math.min(Math.max(0.0, (t - a)/(b - a)), 1.0);
}

function easeInOutInterpolate(t, a, b){
    assert(t >= 0.0 && t <= 1.0); // we don't want any other values
    
    const val = a + t*t*(3.0 - 2.0*t)*(b - a);
    
    return b > a ? Math.max(a,  Math.min(val, b)) : Math.max(b,  Math.min(val, a)); // clamping, since numeric precision might bite here
}

function bezierAxisIntersection(curve, axis, axisPos){
    assert((axisPos >= curve.a[axis] && axisPos <= curve.b[axis]) || (axisPos >= curve.b[axis] && axisPos <= curve.a[axis]));
    
    const pAxis = perpAxis(axis);
    
    const c1 = Point.zero, c2 = Point.zero;
    c1[pAxis] = curve.a[pAxis];
    c1[axis] = (curve.a[axis] + curve.b[axis])/2.0;
    
    c2[pAxis] = curve.b[pAxis];
    c2[axis] = (curve.a[axis] + curve.b[axis])/2.0;
    
    let t = (axisPos - curve.a[axis])/(curve.b[axis] - curve.a[axis]); // first approximation - treating curve as linear segment
    
    const kIterations = 3; // Newton-Raphson iterations
    
    for (let i = 0; i < kIterations; i++) {
      const nt = 1.0 - t;
      
      const f = nt*nt*nt*curve.a[axis] + 3.0*nt*nt*t*c1[axis] + 3.0*nt*t*t*c2[axis] + t*t*t*curve.b[axis] - axisPos;
      const df = -3.0*(curve.a[axis]*nt*nt + c1[axis]*(-3.0*t*t + 4.0*t - 1.0) + t*(3.0*c2[axis]*t - 2.0*c2[axis] - curve.b[axis]*t));
      
      t -= f/df;
    }
    
    assert(t >= 0 && t <= 1.0);
    
    const nt = 1.0 - t;
    const intersection = nt*nt*nt*curve.a[pAxis] + 3.0*nt*nt*t*c1[pAxis] + 3.0*nt*t*t*c2[pAxis] + t*t*t*curve.b[pAxis];
    
    const ret = Point.zero;
    ret[axis] = axisPos;
    ret[pAxis] = intersection;
    
    return ret;
}

export default GenieAnimation;
