import { sheetMaskImage } from "/src/bundled/index";
import CSSMaskBorderPolyfill from "/src/polyfill/CSSMaskBorderPolyfill";

import { Rect } from "cacao/graphics";
import { AsyncImage, ResizableImage } from "/src/graphics/index";
import { GenieAnimation, GenieTransitionAnimator, RectEdge } from "/src/genie/index";

class SheetTransitionPhase {
  static willBegin = 0;
  static didEnd = 1;
}

class SheetTransitionStyle {
  static default = 0;
  static destroy = 1;
}

class SheetTransitionCoordinator {
  delegate;
  
  static get TransitionStyle(){ return SheetTransitionStyle };
  
  constructor({ containerNode, sourceNode, sheetNode, completion } = options){
    this.options = { containerNode, sourceNode, sheetNode, completion };
  }
  
  show(){
    if (this.isTransitioning) {
      console.warn("`show()` called while already in a transition.");
      return;
    }
    
    this.isTransitioning = true;
    
    const present = async () => {
      await this.addSheetMask();
      await this.snapshotter.prepare();
      await this.createSourceImage();
      
      this.animate(true);
    };
    
    window.requestAnimationFrame(present);
  }
  
  dismiss(transitionStyle){
    if (this.isTransitioning) {
      console.warn("`dismiss()` called while already in a transition.");
      return;
    }
    
    this.isTransitioning = true;
    
    const dismiss = async () => {
      this.maskBorderRenderer?.invalidate();
      
      if (transitionStyle == SheetTransitionStyle.destroy){
        this.animateDestroy();
        return;
      }
      
      await this.snapshotter.prepare();
      await this.createSourceImage();
      
      this.animate(false);
    };
    
    window.requestAnimationFrame(dismiss);
  }
  
  // -
  
  async addSheetMask(){
    const { sheetNode } = this.options;
    
    // Apply border mask:
    const maskBorderSource = `url(${sheetMaskImage})`;
    const maskBorderSlice = "92 fill";
    
    if (CSS.supports("mask-border-source", maskBorderSource)){
      sheetNode.style.maskBorderSource = maskBorderSource;
      sheetNode.style.maskBorderSlice = maskBorderSlice;
    } else {
      this.maskBorderRenderer = new CSSMaskBorderPolyfill.Renderer(sheetNode, { maskBorderSource, maskBorderSlice });
    }
  }
  
  async createSourceImage(){
    const { containerNode, sheetNode } = this.options;
    const sheetNodeRect = sheetNode.getBoundingClientRect();
    
    const sheet = await AsyncImage(sheetMaskImage);
    
    const createCanvas = (width, height, scale) => {
      const canvas = document.createElement("canvas");
      canvas.width = width * scale;
      canvas.height = height * scale;
      canvas.style.position = "absolute";
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
      
      const ctx = canvas.getContext("2d");
      ctx.scale(scale, scale);
      
      return { canvas, ctx };
    }
    
    const scale = window.devicePixelRatio || 1;
    const bounds = sheetNodeRect;
    
    const { canvas, ctx } = createCanvas(bounds.width, bounds.height, scale);
    
    const sheetCorner = {
      width: Math.floor(sheet.width / 2) - 1,
      height: Math.floor(sheet.height / 2) - 1
    };
    const padding = { top: sheetCorner.height, right: sheetCorner.width, bottom: sheetCorner.height, left: sheetCorner.width };
    
    // Draw clipping shape:
    const { canvas: maskingCanvas } = createCanvas(bounds.width, bounds.height, scale);
    
    const resizableSheet = new ResizableImage(sheet, { insets: padding });
    const resizedSheet = resizableSheet.resizedTo({ width: bounds.width, height: bounds.height }, scale);
    
    maskingCanvas.getContext("2d").drawImage(resizedSheet, 0, 0, bounds.width, bounds.height);
    
    // Draw content:
    ctx.save();
    this.snapshotter.drawSnapshotInContext(ctx, sheetNode, sheetNodeRect);
    ctx.restore();
    
    ctx.globalCompositeOperation = "destination-in"; // Use destination as a mask
    ctx.fillStyle = "black";
    ctx.drawImage(maskingCanvas, 0, 0, bounds.width, bounds.height);
    
    // Save:
    this.transitionCanvas = canvas;
    this.transitionCanvasScale = scale;
  }
  
  animate(direction){
    const { transitionCanvas } = this;
    const { sheetNode, sourceNode, containerNode } = this.options;
    
    const sheetNodeRect = sheetNode.getBoundingClientRect();
    const sourceNodeRect = sourceNode.getBoundingClientRect();
    
    let destinationRect;
    
    destinationRect =  Rect.make(sourceNodeRect.x, sourceNodeRect.y, sourceNodeRect.width, sourceNodeRect.height);
    destinationRect.origin.y = sourceNodeRect.y + sourceNodeRect.height;
    destinationRect = destinationRect.insetBy(24, 0);
    
    containerNode.style.height = `${sourceNodeRect.y + sourceNodeRect.height}px`;
    containerNode.style.overflow = "hidden";
    
    const options = {
      originRect: Rect.make(sheetNodeRect.x, sheetNodeRect.y, sheetNodeRect.width, sheetNodeRect.height),
      destinationRect,
      destinationEdge: RectEdge.top,
      duration: 800,
      reverse: direction
    };
    
    const animation = new GenieAnimation(options);
    const animator = new GenieTransitionAnimator(animation, transitionCanvas, this.transitionCanvasScale);
    animator.didComplete = () => {
      this.notifyDelegatePhase(SheetTransitionPhase.didEnd, direction);
      
      animator.element.remove();
    };
    
    this.animator = animator;
    
    containerNode.appendChild(animator.element);
    
    this.notifyDelegatePhase(SheetTransitionPhase.willBegin, direction);
    
    sheetNode.style.visibility = "hidden";
    
    animator.animate();
  }
  
  notifyDelegatePhase(phase, direction){
    if (phase == SheetTransitionPhase.didEnd) {
      this.isTransitioning = false;
    }
    
    const { delegate } = this;
    if (!delegate){
      return;
    }
    
    const { willBegin, didEnd } = SheetTransitionPhase;
    
    let fn;
    switch (phase) {
      case willBegin:
        fn = delegate.sheetTransitionCoordinatorWillBegin;
        break;
      case didEnd:
        fn = delegate.sheetTransitionCoordinatorDidComplete;
        break;
    }
    
    if (fn) {
      fn.bind(delegate)(this, direction);
    }
  }
  
  // - Destroy
  
  animateDestroy(){
    this.notifyDelegatePhase(SheetTransitionPhase.willBegin, false);
    
    const { containerNode, sheetNode } = this.options;
    
    const containerNodeRect = containerNode.getBoundingClientRect();
    const sheetNodeRect = sheetNode.getBoundingClientRect();
    
    const keyframes = [
      { transform: new DOMMatrix() },
      { transform: new DOMMatrix().translate(0, sheetNodeRect.height + containerNodeRect.height - sheetNodeRect.y).rotate(25) }
    ];
    
    let contentContainerNode, node = sheetNode;
    do {
      if (node?.classList.contains("sheet-container")) {
        contentContainerNode = node;
        break;
      }
    } while (node && (node = node.parentNode));
    
    const animation = contentContainerNode.animate(keyframes, {
      duration: 900,
      fill: "forwards",
      easing: "ease-in"
    });
    
    animation.finished.then(() => {
      this.notifyDelegatePhase(SheetTransitionPhase.didEnd, false);
    });
  }
  
}

export default SheetTransitionCoordinator;
