import { View } from "cacao/ui";
import { contentEditable } from "/src/text/index";
import { localized } from "/src/locale/index";

class ContactFormView extends View {
  
  constructor(){
    super();
    
    this.node.innerHTML = `
    <label for="name">${localized("contact.name-label")}</label>
    <input type="text" name="name" placeholder="Juan Mantiso" required />
    
    <label for="email">${localized("contact.email-label")}</label>
    <input type="email" name="email" placeholder="${localized("contact.email-placeholder")}" required />
    
    <label for="message">${localized("contact.message-label")}</label>
    <span 
      class="textarea" 
      data-input-name="message"
      data-required=1
      role="textbox"
      aria-multiline="true"
      aria-labelledby="message"
      aria-required="true"
      contenteditable>
      <!-- Forgive me lord -->
    </span>
    `;
    
    this.handleTextAreas();
    
    this.node.addEventListener("submit", (event) => {
      event.preventDefault();
    });
  }
  
  makeNode(document){
    return document.createElement("form");
  }
  
  handleTextAreas(){
    // Textarea handling:
    const textareas = this.node.querySelectorAll(".textarea");
    
    for (const textarea of textareas) {
      const name = textarea.getAttribute("data-input-name");
      
      const backingInputNode = document.createElement("input");
      backingInputNode.setAttribute("type", "text");
      backingInputNode.setAttribute("required", textarea.getAttribute("data-required"));
      backingInputNode.setAttribute("name", name);
      backingInputNode.tabIndex = -1;
      backingInputNode.style.height = "1px";
      backingInputNode.style.marginTop = "-1px";
      backingInputNode.style.overflow = "hidden";
      backingInputNode.style.opacity = "0";
      backingInputNode.onfocus = (event) => {
        event.preventDefault();
        textarea.focus();
      };
      
      // Handle `input` to sync text into the form
      textarea.addEventListener("input", () => {
        // Update backing input:
        backingInputNode.value = textarea.textContent;
        
        // Notify:
        const { textInputDidChange } = this;
        if (textInputDidChange){
          const isAtEnd = contentEditable.isCaretAtEnd(textarea);
          
          textInputDidChange(this, { input: backingInputNode, isAtEnd });
        }
        
      }, false);
      
      // Handle `paste` to filter styled text
      textarea.addEventListener("paste", (e) => {
        e.preventDefault();
        
        const text = e.clipboardData ? e.clipboardData.getData("text/plain") : "";
        
        if (document.queryCommandSupported?.("insertText")) {
          return document.execCommand("insertText", false, text);
        }
        
        const selection = document.getSelection();
        if (!selection) {
          return;
        }
        
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(new Text(text));
        range.collapse(); // select nothing
        selection.removeAllRanges(); // position caret after inserted text
        selection.addRange(range); // show caret
      });
      
      this.node.insertBefore(backingInputNode, textarea);
    }
  }
  
  endEditing(){
    const form = this.node;
    
    for (let i = 0; i < form.elements.length; i++) {
      form.elements[i].blur();
    }
    
    // Clear textareas:
    const textareas = this.node.querySelectorAll(".textarea");
    textareas.forEach(textarea => {
      textarea.blur();
    });
    
    // Needed for some reason:
    window.getSelection().removeAllRanges();
  }
  
  get hasChanges(){
    const form = this.node;
    
    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];
      
      // Skip elements that don't have a value or aren't capable of change
      if (typeof element.value === 'undefined' || element.type === 'submit' || element.type === 'button') {
        continue;
      }
      
      // Text, textarea, and other input types
      else if (element.value !== element.defaultValue) {
        return true;
      }
    }
    
    // If no differences were found
    return false;
  }
  
}

export default ContactFormView;
