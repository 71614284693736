import { View } from "cacao/ui";
import { localized } from "/src/locale/index";
import Button from "./Button";

class SentConfirmationOverlay extends View {
  
  constructor() {
    super();
    
    this.node.className = "sent-confirmation-overlay";
    this.node.innerHTML = `
      <div class="backdrop"></div>
      
      <div class="content-wrapper">
        <h1>${localized("contact.sent-confirmation-title")}</h1>
        <h2>${localized("contact.sent-confirmation-subtitle")}</h2>
      </div>
    `;
    
    const dismissButton = new Button({ type: Button.Type.rounded });
    dismissButton.text = localized("contact.done-button");
    dismissButton.primaryAction = (event) => {
      this.primaryAction(event);
    };
    
    const contentWrapper = this.node.querySelector(".content-wrapper");
    contentWrapper.appendChild(dismissButton.node);
  }
  
  show(options, completion){
    const { containerNode } = options;
    
    const contentNode = this.node.querySelector(".content-wrapper");
    containerNode.appendChild(this.node);
    
    const present = () => {
      this.node.classList.add("presented");
      
      const contentNodeRect = contentNode.getBoundingClientRect();
      
      const animation = contentNode.animate([
        { transform: new DOMMatrix().scale(0.8), opacity: 0, filter: "blur(20px)" },
        { transform: new DOMMatrix(), opacity: 1, filter: "blur(0px)" }
        
      ], { duration: 800, easing: "ease-out" });
      
      if (completion) {
        animation.finished.then(completion);
      }
    };
    
    window.requestAnimationFrame(present);
  }
  
  dismiss(){
    // Unimplemented
  }
  
}

export default SentConfirmationOverlay;
