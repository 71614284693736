import { App } from "cacao/ui";
import { Rect } from "cacao/graphics";

import { ContactView } from "./views/ContactView.js";

class MainActions {
  
  didSelectContact(event){
    event?.preventDefault();
    
    const sheet = new ContactView();
    
    const options = {
      containerElement: document.querySelector("main"),
      sourceElement: event?.target
    };
    
    sheet.show(options);
  }
  
  didSelectHome(event){
    const threshold = 44.0;
    
    if (window.pageYOffset > threshold) {
      event?.preventDefault();
      
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
  
}

class Mantisa extends App {
  
  documentDidLoad(document){
    const actions = new MainActions();
    
    const actionQueryMap = {
      didSelectContact: ".action-contact",
      didSelectHome: "a.mark",
    };
    
    for (const [ name, query ] of Object.entries(actionQueryMap)){
      const fn = actions[name].bind(actions);
      document.querySelectorAll(query).forEach(match => {
        match.addEventListener("click", fn);
      });
    }
  }
  
}

window.app = new Mantisa(document);
