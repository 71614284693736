import { AsyncImage, ResizableImage } from "/src/graphics/index";

class CSSMaskBorderPolyfillRenderer {
  
  constructor(node, options){
    this.node = node;
    this.options = options;
    
    this.initialize();
    
    const resizeListener = this.update.bind(this);
    this.resizeListener = resizeListener;
    window.addEventListener("resize", resizeListener);
  }
  
  invalidate(){
    const { resizeListener } = this;
    if (resizeListener){
      window.removeEventListener("resize", resizeListener);
    }
  }
  
  update(){
    const { node, options, maskSourceImage } = this;
    const { maskBorderSlice } = options;
    
    // Assumes a single integer number, obviously wrong:
    // https://developer.mozilla.org/en-US/docs/Web/CSS/mask-border-slice
    const sliceValues = {
      width: parseInt(maskBorderSlice),
      height: parseInt(maskBorderSlice)
    };
    
    const padding = { top: sliceValues.height, right: sliceValues.width, bottom:
    sliceValues.height, left: sliceValues.width };
    
    const rect = node.getBoundingClientRect();
    
    if (rect.width <= 0 || rect.height <= 0) {
      return;
    }
    
    if (rect.width == this.latestWidth && rect.height == this.latestHeight) {
      return;
    }
    
    this.latestWidth = rect.width;
    this.latestHeight = rect.height;
    
    const scale = window.devicePixelRatio || 1;
    const scaledWidth = rect.width * scale;
    const scaledHeight = rect.height * scale;
    
    let canvas = this.canvas;
    if (!canvas){
      canvas = document.createElement("canvas");
      
      this.canvas = canvas;
      this.ctx = canvas.getContext("2d");
    }
    canvas.width = scaledWidth;
    canvas.height = scaledHeight;
    
    const ctx = this.ctx;
    ctx.scale(scale, scale);
    
    const resizableImage = new ResizableImage(maskSourceImage, { insets: padding });
    const resizedImage = resizableImage.resizedTo({ width: rect.width, height: rect.height }, scale);
    
    ctx.drawImage(resizedImage, 0, 0, rect.width, rect.height);
    
    const img = canvas.toDataURL("image/png");
    
    const { style } = this.node;
    style.maskImage = `url(${img})`;
    style.maskSize = `${rect.width}px ${rect.height}px`;
    style.maskRepeat = "no-repeat";
  }
  
  async initialize(){
    const { maskBorderSource } = this.options;
    
    function extractURL(declaration) {
      const regex = /url\(['"]?(.*?)['"]?\)/;
      const match = declaration.match(regex);
      return match ? match[1] : null;
    }
    
    let { maskSourceImage } = this;
    if (!maskSourceImage) {
      const sourceURL = extractURL(maskBorderSource);
      maskSourceImage = await AsyncImage(sourceURL);
      
      this.maskSourceImage = maskSourceImage;
    }
    
    window.requestAnimationFrame(() => {
      this.update();
    });
  }
  
}

export default { Renderer: CSSMaskBorderPolyfillRenderer };
