import APIClient from "./APIClient";

let defaultBaseURL = "https://api.mantisa.com/";

if (process.env.NODE_ENV === "development") {
  // Only runs in development and will be stripped from production build.
  defaultBaseURL = "http://localhost:3000";
}

const sharedClient = new APIClient({ baseURL: defaultBaseURL });
APIClient.sharedClient = sharedClient;

export {
  APIClient
};
