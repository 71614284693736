class RectEdge {
  static top = 0;
  static left = 1;
  static bottom = 2;
  static right = 3;
  
  static description(fromEdge){
    const { top, left, bottom, right } = this;
    const map = {
      [top]: "top",
      [left]: "left",
      [bottom]: "bottom",
      [right]: "right"
    };
    return map[fromEdge] || "unknown";
  }
}

export default RectEdge;
