class APIClient {
  
  constructor({ baseURL } = options) {
    this.baseURL = baseURL;
  }
  
  async submitContact({ name, email, message } = params){
    const path = "/api/hello";
    const data = {
      sender: {
        name, email
      },
      message
    };
    
    const requestURL = new URL(path, this.baseURL);
    
    let response;
    
    try {
      response = await fetch(requestURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      });
    } catch (error){
      console.error("[APIClient] An error has ocurred", error);
    }
    
    if (response && response.ok) {
      return response;
    } else {
      return undefined;
    }
  }
  
}

export default APIClient;
