class ShakeAnimator {
  
  constructor(){
    const iterations = 200;
    const keyframes = [];
    
    const e = 2.5;
    let position = 0;
    
    for (let t = 0; t < iterations; t += 1){
      position = 42 * Math.pow(e, -0.022 * t) * Math.sin(0.12 * t);
      
      const transform = new DOMMatrix().translate(position, 0);
      keyframes.push({ transform });
    }
    
    this.keyframes = keyframes;
  }
  
  animate(target, completion){
    const { keyframes } = this;
    
    const animation = target.animate(keyframes, { duration: 600 });
    
    if (completion){
      animation.finished.then(completion);
    }
  }
  
}

export default ShakeAnimator;
